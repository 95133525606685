<script setup>
const props = defineProps(["src", "options"]);

const svgHtmlEl = ref(null);

function updateUI() {
  if (!svgHtmlEl.value) return;

  const svg = svgHtmlEl.value.querySelector("svg");

  svg.style.width = `100%`;
  svg.style.height = `100%`;

  if (props.options?.color) svg.style.color = props.options.color;
  if (props.options?.stroke) svg.style.stroke = props.options.stroke;

  var paths = svg.querySelectorAll("path");

  Array.from(paths).map((path) => {
    if (props.options?.color) path.style.fill = props.options.color;
    if (props.options?.stroke) path.style.stroke = props.options.stroke;
    if (props.options?.strokeWidth) path.style.strokeWidth = props.options.strokeWidth;
  });
}

async function loadSvg() {
  const svg = await loadFile(props.src);
  if (svg) svgHtmlEl.value.innerHTML = svg;
}

function watchProps() {
  watch(
    () => props.src,
    async () => {
      await loadSvg();
      updateUI();
    },
    {
      immediate: true,
    }
  );
  watch(
    () => [props.src, svgHtmlEl.value, props.options],
    () => {
      updateUI();
    }
  );
}

onMounted(() => {
  watchProps();
});
</script>
<template>
  <div class="svg-el">
    <div
      :ref="(el) => (svgHtmlEl = el)"
      :style="{
        color: options?.color,
        width: options?.width,
        height: options?.height,
      }"
    ></div>
  </div>
</template>
<style scoped>
.svg-el {
  pointer-events: none;
  display: inline-block;
}
</style>
