export async function loadFile(svgUrl: string) {
    var SVGFile = svgUrl;
    var httpReq = new XMLHttpRequest();

    const svgPromise = new Promise((resolve) => {
        if (httpReq != null) {
            httpReq.open("GET", SVGFile, true);
            httpReq.onreadystatechange = handler;
            httpReq.send();
        }

        function handler() {
            if (httpReq.readyState == 4 && httpReq.status == 200) {
                resolve(httpReq.responseText);
            }
        }
    });

    return svgPromise;
}